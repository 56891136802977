<template>
  <a
    href="javascript:void(0)"
    class="btn btn-icon btn-light btn-hover-success btn-sm mx-1"
    v-b-tooltip.hover.bottom
    :title="$t('TABLES.ADD_PROJECT_TEMPLATE_TOOLTIPS.CREATE')"
    @click="$emit('click')"
  >
    <span class="svg-icon svg-icon-md svg-icon-success">
      <inline-svg src="media/svg/icons/General/Duplicate.svg" title=" " />
    </span>
  </a>
</template>

<script>
export default {
  name: "CreateProjectFromTemplate"
}
</script>