<template>
  <div>
    <div v-if="error == null" class="d-flex justify-content-between mb-10">
      <div class="form-group mb-0 col-xl-6 px-0">
        <input
          id="project_report_table_search"
          v-model="search"
          type="text"
          :placeholder="$t('GENERAL.INPUT.SEARCH')"
          class="form-control form-control-solid h-auto py-3 px-5 w-75"
          :disabled="loadingComplete == false"
        />
      </div>
    </div>
    <div class="table-responsive">
      <table
        id="project_reports_table"
        class="table table-head-custom table-vertical center table-hover"
        style="table-layout:auto; overflow: auto"
      >
        <thead>
          <tr>
            <th class="px-2 py-3" rowspan="2" style="width:30px"></th>
            <th class="px-2 py-3" rowspan="2" style="min-width:250px">
              {{ $t('TABLES.PROJECT_TEMPLATE_HEADERS.NAME') }}
            </th>
            <th class="px-2 py-3 text-center" rowspan="2" style="min-width:100px">
              {{ $t('TABLES.PROJECT_TEMPLATE_HEADERS.STATUS') }}
            </th>
            <th class="px-2 py-3 text-left" rowspan="2" style="min-width:120px">
              {{ $t('TABLES.PROJECT_TEMPLATE_HEADERS.LANGUAGE') }}
            </th>
            <th class="px-2 py-3 text-left" rowspan="2" style="min-width:120px">
              {{ $t('TABLES.PROJECT_TEMPLATE_HEADERS.CREATED_ON') }}
            </th>
            <th class="px-2 py-3 text-center" rowspan="2" style="min-width:120px">
              {{ $t('TABLES.PROJECT_TEMPLATE_HEADERS.CREATED_PROJECTS') }}
            </th>
            <th class="px-2 py-3 text-right" rowspan="2" style="min-width:130px"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(template, t) in templateList">
            <ProjectTemplateRow
              v-if="templateInSearch(template)"
              :key="t"
              :template="template"
              :type="type"
            />
          </template>
          <tr v-if="templateList.length == 0 && loadingComplete">
            <td class="px-2 text-center" colspan="7">
              <span class="text-dark-65 font-weight-bold d-block py-3">
                {{ $t('TABLES.PROJECT_TEMPLATE_MESSAGES.NO_TEMPLATES_TEXT') }}
              </span>
            </td>
          </tr>
          <tr v-if="!loadingComplete && error == null">
            <td class="px-2 text-center border-0" colspan="7">
              <b-spinner
                variant="primary"
                label="Spinning"
                class="table-loader my-3"
              >
              </b-spinner>
            </td>
          </tr>
          <tr v-if="error != null">
            <td class="px-2 text-center border-0" colspan="7">
              <b-alert show variant="light">
                {{ error.message }}
              </b-alert>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ProjectTemplateRow from "../table-rows/ProjectTemplateRow";

export default {
  name: "ProjectTemplatesTable",

  props: {
    templateList: { type: Array, required: true },
    loadingComplete: { type: Boolean, required: true },
    error: { type: Object, required: false },
    type: { type: String, required: true, validator(value) { return ['system','client'].includes(value) } }
  },

  data() {
    return {
      search: ""
    };
  },

  components: {
    ProjectTemplateRow
  },

  methods: {
    templateInSearch(template) {
      let name = template.name.toLowerCase();
      let languageName = template.language.name.toLowerCase();
      let languageNative = template.language.native_name.toLowerCase();

      if (
        name.includes(this.search.toLowerCase()) ||
        languageName.includes(this.search.toLowerCase()) ||
        languageNative.includes(this.search.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>