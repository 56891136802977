<template>
  <b-modal size="lg" ref="modal">
    <template #modal-title>
      {{ $t('FORM_HEADERS.PROJECT_FROM_TEMPLATE.POST') }}
      <small class="pl-2">{{ template.name }}</small>
    </template>
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-outline-primary mr-3"
          @click="onCancel"
          :disabled="submissionStates.submitting"
        >
          {{ $t('GENERAL.BUTTONS.CANCEL') }}
        </button>
        <button
          class="btn btn-primary"
          @click="onSubmit"
          :disabled="submissionStates.submitting"
        >
          {{ $t('GENERAL.BUTTONS.SAVE') }}
        </button>
      </div>
    </template>
    <ErrorBox
      v-if="projectErrors != null"
      :errors="projectErrors"
      @clear_errors="setProjectErrors(null)"
    >
    </ErrorBox>
    <div class="p-5">
      <div class="row mx-0">
        <GenericTextInput
          id="input-name"
          class="mb-5 col-8 px-0 pr-5"
          :model="$v.form.name"
          :label="$t('FORMS.INPUTS.NAME')"
          alignLabel="left"
          :placeholder="$t('FORMS.INPUTS.NAME')"
          :showInline="false"
          :error="$t('FORMS.ERROR.REQUIRED')"
          :submissionStates="submissionStates"
        />

        <GenericTextInput
          id="input-key"
          class="mb-5 col-4 px-0 pl-5 key-field"
          :model="$v.form.key"
          :label="$t('FORMS.INPUTS.URL_PREFIX')"
          alignLabel="left"
          :placeholder="$t('FORMS.INPUTS.URL_PREFIX')"
          :showInline="false"
          :error="$t('FORMS.ERROR.REQUIRED')"
          :submissionStates="submissionStates"
        />   
      </div>

      <h3 class="font-size-lg text-dark font-weight-bold mb-6 mt-10 px-0"> {{ $t('FORMS.SECTION_HEADERS.PROJECT_DATES') }} </h3>

      <div class="row">
        <InputGenericDatePicker
          id="project-input-start"
          class="col-4 pr-5"
          :label="$t('FORMS.INPUTS.INPUT_START')"
          :model="$v.form.starts_on"
          :submissionStates="submissionStates"
          :notAfter="inputStartUL"
          :error="$t('FORMS.ERROR.REQUIRED')"
          :helper="$t('MANAGE_PROJECT.HELPERS.STARTS_ON')"
          type="datetime"
          @date_updated="form.starts_on = $event"
        />

        <InputGenericDatePicker
          id="project-input-end"
          class="col-4 px-5"
          :label="$t('FORMS.INPUTS.INPUT_END')"
          :model="$v.form.ends_on"
          :submissionStates="submissionStates"
          :notBefore="inputEndLL"
          :notAfter="null"
          :error="$t('FORMS.ERROR.REQUIRED')"
          :helper="$t('MANAGE_PROJECT.HELPERS.ENDS_ON')"
          type="datetime"
          @date_updated="form.ends_on = $event"
        />

        <InputGenericDatePicker
          id="project-admin-end"
          class="col-4 pl-5"
          :label="$t('FORMS.INPUTS.ADMIN_END')"
          :model="$v.form.admin_end_date"
          :submissionStates="submissionStates"
          :notBefore="adminEndLL"
          :notAfter="adminEndUL"
          :error="$t('FORMS.ERROR.REQUIRED')"
          :helper="$t('MANAGE_PROJECT.HELPERS.ADMIN_END')"
          type="datetime"
          @date_updated="form.admin_end_date = $event"
        />
      </div>

      <h3 class="font-size-lg text-dark font-weight-bold mb-6 mt-10 px-0"> {{ $t('FORMS.SECTION_HEADERS.MISCELLANEOUS') }} </h3>

      <div class="row">
        <InputGenericDropdown
          id="input-deal-owner"
          class="col-xl-4 pr-5"
          :label="$t('FORMS.INPUTS.DEAL_OWNER')"
          :model="$v.form.deal_owner"
          :options="userList"
          optionLabel="calculatedName"
          value="id"
          :errorMessage="$t('FORMS.ERROR.REQUIRED')"
        />

        <InputGenericDropdown
          id="input-deal-owner"
          class="col-xl-4 pr-5"
          :label="$t('FORMS.INPUTS.PARTICIPANT_LIMIT')"
          :model="$v.form.participant_limit"
          :options="supportedParticipantsList"
          optionLabel="name"
          value="id"
          :errorMessage="$t('FORMS.ERROR.REQUIRED')"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { 
  mapActions,
  mapMutations,
  mapGetters
} from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import PageLoader from "@/core/classes/PageLoader.js";
import AutoCompleteHelper from "@/helpers/AutoCompleteHelper";
import moment from "moment";
import Toast from "@/core/classes/Toast.js";

import ErrorBox from "@/view/components/ErrorBox";
import GenericTextInput from "@/modules/together-helpers/components/generic-inputs/GenericTextInput";
import InputGenericDatePicker from "@/modules/together-helpers/components/generic-inputs/GenericDatePicker";
import InputGenericDropdown from "@/modules/together-helpers/components/generic-inputs/GenericDropdown";

import { POPULATE_CLIENT_USER_LIST } from "@/core/services/store/core/user.module";

export default {
  mixins: [validationMixin],

  name: "CreateProjectTemplate",

  props: {
    template: { type: Object, required: true }
  },

  data() {
    return {
      toast: new Toast(),
      pageLoader: new PageLoader(2),
      submissionStates: {
        submitting: false
      },
      form: {
        name: "",
        key: "",
        demo_project: 0,
        starts_on: null,
        ends_on: null,
        admin_end_date: null,
        deal_owner: "",
        participant_limit: 1,
      },
      form_reset: {},
      allow_key_editing: true,
      supportedParticipantsList: []
    }
  },

  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
      },
      key: {
        required,
        maxLength: maxLength(35)
      },
      demo_project: {},
      starts_on: {
        required
      },
      ends_on: {
        required
      },
      admin_end_date: {
        required
      },
      deal_owner: {
        required
      },
      participant_limit: {
        required
      }
    }
  },

  components: {
    ErrorBox,
    GenericTextInput,
    InputGenericDatePicker,
    InputGenericDropdown
  },

  mounted() {
    this.cleanup();
    this.fetchSupportedParticipantRanges();
    this.fetchClientAdministrators();
    this.form.starts_on = moment(new Date()).utc().format("YYYY-MM-DD") + " 00:00:00";
    this.form.ends_on = moment(this.form.starts_on, "YYYY-MM-DD HH:mm:ss").utc().add('days', 30).format("YYYY-MM-DD") + " 00:00:00";
    this.form_reset = JSON.parse(JSON.stringify(this.form));
  },

  methods: {
    fetchClientAdministrators: function() {
      let payload = {
        clientId: this.clientInfo.id,
        params: {
          paginate: false,
          read_only: 1,
          setup_complete: 1
        }
      };
      this.$store
        .dispatch(POPULATE_CLIENT_USER_LIST, payload)
        .then(() => {
          if (this.submissionType == "post") {
            this.form.deal_owner = this.userList[0].id;
          }
          this.pageLoader.setCompleteComponent();
        });
    },
    fetchSupportedParticipantRanges: function() {
      let payload = {
        route: "api/v1/credit_schemes_rules/supported_paticipants"
      };
      this.$store.dispatch(GENERIC_RESOURCE, payload).then((res) => {
        this.supportedParticipantsList = res;
        this.pageLoader.setCompleteComponent();
      });
    },
    autofillKey() {
      if (this.allow_key_editing) {
        this.form.key = AutoCompleteHelper.generateKey(this.form.name);
      }
    },
    autoFillAdminEnd: function() {
      if (this.form.ends_on != null && (this.form.ends_on > this.form.admin_end_date || this.form.admin_end_date == null)) {
        let adminEnd = moment.utc(this.form.ends_on, "YYYY-MM-DD HH:mm:ss").add('days', 40).format("YYYY-MM-DD HH:mm:ss");
        this.form.admin_end_date = adminEnd;
      }
    },
    onCancel: function() {
      this.resetForm();
      this.$refs.modal.hide();
    },
    onSubmit: function() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.submissionStates.submitting = true;

      let payload = {};
      payload.data = this.form;
      payload.clientId = this.clientInfo.id;
      payload.data.based_on = this.template.id;

      this.createProjectFromTemplate(payload)
        .then(() => {
          this.cleanup();
          this.toast.notify(this, this.form.name + ' ' + `${this.$t("TOASTS.ADD_PROJECT_FROM_TEMPLATE.MESSAGE")}`, `${this.$t("TOASTS.ADD_PROJECT_FROM_TEMPLATE.TITLE")}`, 'success')
          this.$router.push({ name: "client_projects" })
        }).finally(() => {
          this.submissionStates.submitting = false;
        })
    },
    cleanup: function() {
      this.setProjectErrors();
    },
    resetForm: function() {
      this.$v.form.$reset()
      this.form = JSON.parse(JSON.stringify(this.form_reset));
    },
    ...mapActions({
      createProjectFromTemplate: "createProjectFromTemplate"
    }),
    ...mapMutations({
      setProjectErrors: "setProjectErrors"
    })
  },

  watch: {
    calculatedName: function () {
      this.autofillKey();
    },
    'form.key': function(newValue) {
      if (newValue != AutoCompleteHelper.generateKey(this.form.name)) {
        this.allow_key_editing = false;
      }
    },
    calculatedInputEnd: function() {
      this.autoFillAdminEnd();
    }
  },

  computed: {
    ...mapGetters([
      "clientInfo",
      "projectErrors",
      "userList",
      "currentUser"
    ]),
    loadingComplete: function() {
      return this.pageLoader.getStatus();
    },
    calculatedName: function() {
      return this.form.name;
    },
    calculatedInputEnd: function() {
      return this.form.ends_on;
    },
    inputStartUL: function() {
      if (this.form.ends_on == null) {
        return null;
      } else {
        return this.form.ends_on;
      }
    },
    inputEndLL: function() {
      if (this.form.starts_on == null) {
        return moment(new Date()).utc().format("YYYY-MM-DD") + " 00:00:00";
      } else {
        return this.form.starts_on;
      }
    },
    adminEndLL: function() {
      if (this.form.ends_on == null) {
        return moment(new Date()).utc().format("YYYY-MM-DD") + " 00:00:00";
      } else {
        return this.form.ends_on;
      }
    },
    adminEndUL: function() {
      if (this.form.ends_on == null || this.currentUser.system_admin_role_id == 1) {
        return null;
      } else {
        let date = moment(this.form.ends_on, "YYYY-MM-DD HH:mm:ss").add('days', 40).utc().format(("YYYY-MM-DD"));
        return date + " 00:00:00";
      }
    },
  }
}
</script>