<template>
  <a
    href="javascript:void(0)"
    class="btn btn-icon btn-light btn-hover-danger btn-sm mx-1"
    v-b-tooltip.hover.bottom
    :title="$t('TABLES.PROJECT_TEMPLATE_TOOLTIPS.DELETE')"
    @click="onDelete()"
  >
    <span class="svg-icon svg-icon-md svg-icon-danger">
      <inline-svg src="media/svg/icons/Home/Trash.svg" title=" " />
    </span>
  </a>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";

export default {
  name: "DeleteProjectTemplate",

  props: {
    template: { type: Object, required: true },
    type: { type: String, required: true, validator(value) { return ['system','client'].includes(value) } }
  },

  methods: {
    onDelete: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.DELETE_PROJECT_TEMPLATE_CHALLENGE.TITLE")}`,
        text: this.template.name + " " + `${this.$t("SWEETALERTS.DELETE_PROJECT_TEMPLATE_CHALLENGE.MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.DELETE_PROJECT_TEMPLATE_CHALLENGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          let url = (this.type == 'client') ? `api/v1/clients/${this.template.client_id}/project_templates/${this.template.id}` : `api/v1/system_templates/${this.template.id}`;
          let payload = { url: url, projectTemplateId: this.template.id };
          this.deleteProjectTemplate(payload)
          .then(() => {
            this.$swal.fire({
              title: `${this.$t("SWEETALERTS.DELETE_PROJECT_TEMPLATE_SUCCESS.TITLE")}`,
              text: this.template.name + ' ' + `${this.$t("SWEETALERTS.DELETE_PROJECT_USER_SUCCESS.MESSAGE")}`,
              icon: 'success',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })          
          })
          .catch(() => {
            this.$swal.fire({
              title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
              text: `${this.$t("SWEETALERTS.REVOKE_PROJECT_TEMPLATE_FAIL.MESSAGE")}`,
              icon: 'error',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })  
          })
        }
      })
    },
    ...mapActions({
      deleteProjectTemplate: "deleteProjectTemplate"
    })
  },
  computed: {
    ...mapGetters([
      "clientInfo"
    ])
  }
}
</script>