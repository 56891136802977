<template>
  <a
    :href="projectLink"
    class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
    v-b-tooltip.hover.bottom
    :title="$t('TABLES.PROJECT_TEMPLATE_TOOLTIPS.EDIT')"
  >
    <span class="svg-icon svg-icon-md svg-icon-primary">
      <inline-svg src="media/svg/icons/Design/Edit.svg" title=" " />
    </span>
  </a>
</template>

<script>
export default {
  name: "EditProjectTemplate",

  props: {
    projectLink: { type: String, required: true }
  }
}
</script>