<template>
  <tr
    v-on:mouseover="hovering = 1"
    v-on:mouseleave="hovering = 0" 
  >
    <td class="py-3 px-0 text-center" style="width:45px">
      <PublishedState
        v-if="type == 'system' || template.system_project_template_type_id == 2"
        :componentId="template.id"
        updateUri="project_templates"
        :published="template.published"
        @changed="updatePublishedState($event)"
      >
      </PublishedState>
    </td>
    <td class="px-2 py-3 d-table-cell">
      <component
        class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
        :is="(allowEdit()) ? 'a' : 'span'"
        :href="(allowEdit()) ? projectLink : null"
      >
        {{ template.name }}
      </component>
      <span class="text-muted font-weight-bold text-muted d-block">{{ $t(`SYSTEM.SYSTEM_PROJECT_TEMPLATE_TYPE.${template.type.id}`) }}</span>
    </td>
    <td class="px-2 py-5 text-center">
      <span
        class="label label-pill label-inline ml-3"
        :class="computedStatus.state"
        style="position:relative; top:-1px"
      >
        {{ computedStatus.text }}
      </span>
    </td>
    <td class="px-2 py-5 d-table-cell">
      {{ template.language.native_name }}
    </td>
    <td class="py-5">
      <p
        class="d-inline-block text-muted font-weight-bold text-muted font-size-sm mb-0"
      >
        <GenericDatetimeDisplay
          :long="true"
          :date="template.created_at"
          :createdAt="true"
          :lastUpdated="false"
          :relative="false"
        >
        </GenericDatetimeDisplay>
      </p>
    </td>
    <td class="px-2 py-5 d-table-cell text-center">
      {{ template.created_projects }}
    </td>
    <td class="px-2 py-4 text-right">
      <div v-if="hovering">
        <Create
          v-if="allowCreate() == true && userCanCreate() == true"
          @click="openCreateProjectModal(template)"
        />
        <Edit
          v-if="allowEdit() == true && userCanEdit() == true"
          :projectLink="projectLink"
        />
        <Delete
          v-if="allowDelete() == true && userCanDelete() == true"
          :template="template"
          :type="type"
        />        
      </div>
    </td>

    <CreateProject
      :template="template"
      ref="createProjectFromTemplateModal"
    >
    </CreateProject>

  </tr>
</template>

<script>
import {
  mapGetters,
  mapMutations
} from "vuex";
import PublishedState from "@/view/components/table-columns/general/PublishedState";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

import Create from "../table-actions/CreateProjectFromTemplate";
import Edit from "../table-actions/EditProjectTemplate";
import Delete from "../table-actions/DeleteProjectTemplate";

import CreateProject from "@/view/components/modals/CreateProjectFromTemplate";

export default {
  name: "ProjectTemplateRow",

  props: {
    template: { type: Object, required: true },
    type: { type: String, required: true, validator(value) { return ['system','client'].includes(value) } }
  },

  data() {
    return {
      hovering: false
    }
  },

  components: {
    PublishedState,
    GenericDatetimeDisplay,
    Create,
    Edit,
    Delete,
    CreateProject
  },

  methods: {
    allowCreate: function() {
      return (this.type == "client" && this.template.published) ? true : false;
    },
    allowEdit: function() {
      return (
        this.template.generating == 0 && (this.type == "system" || this.template.system_project_template_type_id == 2)) ? true : false;
    },
    allowDelete: function() {
      return (this.type == "system" || this.template.system_project_template_type_id == 2) ? true : false;
    },
    openCreateProjectModal: function() {
      this.$refs.createProjectFromTemplateModal.$refs.modal.show();
    },
    userCanCreate: function() {
      return this.authUserIsSystemAdmin || this.authUserIsClientAdmin;
    },
    userCanEdit: function() {
      return this.authUserIsSystemAdmin || this.authUserIsClientAdmin;
    },
    userCanDelete: function() {
      return this.authUserIsSystemAdmin || this.authUserIsClientAdmin;
    },
    updatePublishedState: function(publishedState) {
      let updatedTemplate = this.template;
      updatedTemplate.published = publishedState;
      this.resetProjectTemplate(updatedTemplate);
    },
    ...mapMutations({
      resetProjectTemplate: "resetProjectTemplate"
    })
  },

  computed: {
    ...mapGetters([
      "clientInfo",
      "currentUser"
    ]),
    authUserIsSystemAdmin: function() {
      return this.currentUser.system_admin_role_id == 1;
    },
    authUserIsClientAdmin: function() {
      const self = this;
      let clientEntries = this.currentUser.clients.filter(function(client) {
        return client.id == self.clientInfo.id;
      });

      if (clientEntries.length == 0) {
        return false;
      } else {
        let clientEntry = clientEntries[0];
        if (clientEntry.pivot.system_client_role_id == 1) {
          return true;
        } else {
          return false;
        }
      }
    },
    computedType: function() {
      switch (this.template.system_project_template_type_id) {
        case 1:
          return "system"
        case 2:
          return {
            state: 'label-light-warning',
            text: 'Client Template'
          }
        default:
          return {}
      }
    },
    computedStatus: function() {
      if (this.template.generating == 1) {
        return {
          state: 'label-secondary',
          text: `${this.$t("TABLES.PROJECT_TEMPLATE_LABELS.GENERATING")}`
        }        
      } else {
        if (this.template.published == 0) {
          return {
            state: 'label-light-danger',
            text: `${this.$t("TABLES.PROJECT_TEMPLATE_LABELS.NOT_PUBLISHED")}`
          }
        } else {
          return {
            state: 'label-light-success',
            text: `${this.$t("TABLES.PROJECT_TEMPLATE_LABELS.READY")}`
          }
        }
      }
    },
    projectLink: function() {
      let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
      return `${protocol}${this.template.link}.${process.env.VUE_APP_ADMIN_URL_SUFFIX}/#/project/activities/view`
    }
  }
}
</script>